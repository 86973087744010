import { Modal } from "antd";
import React, { useContext } from "react";
import XmasOffer from "../../../assets/video/xmas.gif";
import { AppContext } from "../../../AppContext";
import * as ActionTypes from "../../../common/actionTypes";

const BlackFridayModal = ({ visible, setVisible }) => {
  const { dispatch } = useContext(AppContext);

  const handleCancel = () => {
    setVisible(false);
    dispatch({ type: ActionTypes.BLACK_FRIDAY_OFFER_MODAL, data: true });
  };

  return (
    <Modal
      width={600}
      className="halloween-modal"
      keyboard={false}
      onCancel={handleCancel}
      centered
      visible={visible}
      footer={null}
    >
      <a
        href="https://www.contactbook.app/pricing"
        target="_blank"
        rel="noreferrer"
      >
        <img src={XmasOffer} alt="background" />
      </a>
    </Modal>
  );
};

export default BlackFridayModal;
